<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item>重要通知</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="list">
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="name" label="通知">
        </el-table-column>
        <el-table-column prop="time" label="发布时间" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>

  </div>
</template>

<script>
export default {
  name: "TZ_list",
  data() {
    return {
      list: [
        {
          name: "国家卫生健康委办公厅关于印发急诊医学等6个专业医疗质量控制指标（2024年版）的通知",
          link: '/YLZLKZBZ2024',
          time: "2024-05-29",
        },
        {
          name: "关于召开2024年山东省医学影像学质量控制中心工作会议的通知",
          time: "2024-01-26",
          link: '/XW2',
        }
      ]
    }
  },
  methods: {
    handleClick(row) {
      this.$router.push(row.link)
    }
  }
}
</script>

<style scoped>
.list {
  margin: 20px auto;
  width: 80%;
}
</style>